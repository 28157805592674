import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContainerComponent } from '../components/container/container.component';

@Component({
  selector: 'app-info-banner',
  imports: [ContainerComponent],
  templateUrl: './info-banner.component.html',
  styleUrl: './info-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBannerComponent {
  info = [
    {
      text:
        'Während der Feiertage ist die Versandszeit länger als gewohnt. Bestellungen\n' +
        '    bis zum 20. Dezember werden noch vor Weihnachten geliefert. Bestellungen bis\n' +
        '    zum 23. Dezember werden noch bis Neujahr geliefert. Zwischen 23. Dezember\n' +
        '    und 5. Januar werden Supportanfragen nur eingeschränkt beantwortet.',
      validFrom: new Date('2024-12-19'),
      validTo: new Date('2025-01-05'),
    },
  ];

  infoTexts = this.info.filter(
    (i) => i.validFrom <= new Date() && i.validTo >= new Date(),
  );
}
