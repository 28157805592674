import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  OnChanges,
  signal,
  SimpleChanges,
} from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-media-image',
  imports: [NgOptimizedImage, SpinnerComponent],
  templateUrl: './media-image.component.html',
  styleUrl: './media-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaImageComponent implements OnChanges {
  image = input.required<{ url: string }>();
  alt = input.required<string>();
  title = input<string>();
  size = input.required<'small' | 'large' | 'extra-large'>();
  objectFit = input.required<'object-cover' | 'object-contain'>();
  fetchpriority = input<'auto' | 'high' | 'low' | 'none'>('auto');
  loading = signal(true);
  imageLoading = input<'lazy' | 'eager'>('lazy');
  src = '';

  height = computed(() => {
    const size = this.size();
    if (size === 'small') {
      return 'h-20';
    } else if (size === 'large') {
      return 'h-64';
    }
    return 'h-64 md:h-desktop-media';
  });

  loaded() {
    this.loading.set(false);
  }

  errored() {
    this.loading.set(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('url' in changes) {
      this.loading.set(true);
    }
  }
}
