import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
} from '@angular/core';
import { Location, NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../product.service';
import { CartService } from '../../cart.service';
import { ProductListComponent } from '../../components/product-list/product-list.component';
import { ProductPropertiesComponent } from '../../components/product-properties/product-properties.component';
import { ProductMetaComponent } from '../../components/product-meta/product-meta.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ContainerComponent } from '../../components/container/container.component';
import { MediaSliderComponent } from '../../components/media-slider/media-slider.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { MetaService } from '../../meta.service';
import { AnalyticsService } from '../../analytics.service';
import { ProductCartSectionComponent } from '../product-cart-section/product-cart-section.component';
import { ProductBenefitsComponent } from '../product-benefits/product-benefits.component';
import { ProductTipsComponent } from '../product-tips/product-tips.component';
import { ProductBreadcrumbComponent } from '../product-breadcrumb/product-breadcrumb.component';
import { ProductFactsComponent } from '../product-facts/product-facts.component';
import { ProductReviewComponent } from '../product-review/product-review.component';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { Schemas } from '../../../api-types/storeApiTypes';
import { WishlistStateService } from '../../account/wishlist-state.service';
import { BenefitTeaserComponent } from '../../benefit-teaser/benefit-teaser.component';

@Component({
  selector: 'app-product-detail-page',
  imports: [
    ContainerComponent,
    MediaSliderComponent,
    ProductListComponent,
    SpinnerComponent,
    NotFoundComponent,
    ProductPropertiesComponent,
    ProductMetaComponent,
    NgClass,
    ProductCartSectionComponent,
    ProductBenefitsComponent,
    ProductTipsComponent,
    ProductBreadcrumbComponent,
    ProductFactsComponent,
    ProductReviewComponent,
    BenefitTeaserComponent,
    FaIconComponent,
  ],
  templateUrl: './product-detail-page.component.html',
  styleUrl: './product-detail-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailPageComponent {
  readonly favoriteIcon = faHeart;

  readonly product = inject(ProductService);
  readonly meta = inject(MetaService);
  readonly cart = inject(CartService);
  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);
  readonly location = inject(Location);
  readonly analytics = inject(AnalyticsService);
  readonly wishlist = inject(WishlistStateService);
  readonly query = toSignal(this.route.queryParams);

  readonly plant = this.product.detailStore;
  readonly familyPlants = this.product.familyPlantStore;

  readonly productDescription = computed(() => {
    const plantState = this.plant.state();
    if (plantState.loading || !plantState.element) {
      return '';
    }
    const value = plantState.element.description ?? '';
    return value
      .replaceAll(/style="[^"]*"/gm, '')
      .replaceAll(/class="[^"]*"/gm, '');
  });

  readonly media = computed(() => {
    const plant = this.plant.state();
    if (plant.loading || !plant.element) {
      return [];
    }
    return (plant.element.media ?? [])
      .sort((f, s) => {
        return (f.position ?? 0) - (s.position ?? 0);
      })
      .map((m) => m.media);
  });

  readonly isOnWishlist = computed(() => {
    const plant = this.plant.state();
    if (plant.loading || !plant.element) {
      return { state: false };
    }
    return this.wishlist.isOnWishlist(plant.element.id)();
  });

  constructor() {
    effect(() => {
      const plant = this.plant.state();
      if (plant.loading || !plant.element) {
        return;
      }

      this.meta.setMetaTags(
        plant.element.translated,
        plant.element.seoUrls ? plant.element.seoUrls[0] : undefined,
      );

      this.analytics.event('view_item', {
        currency: 'EUR',
        value: plant.element.calculatedPrice.totalPrice,
        items: [
          {
            item_id: plant.element.id,
            item_name: plant.element.name,
            index: 0,
            price: plant.element.calculatedPrice.totalPrice,
            quantity: 1,
          },
        ],
      });
    });
  }

  async toggleFavorite(product: Schemas['Product'], state: boolean) {
    if (!state) {
      await this.wishlist.addToWishlist(product);
    } else {
      await this.wishlist.removeFromWishlist(product.id);
    }
  }

  similarSelected() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
