<app-header [header]="'Impressum'"></app-header>

<app-container>
  <div class="prose py-8">
    <p>
      Tropico Trading GmbH<br />
      Lettenstrasse 6a<br />
      6343 Rotkreuz<br />
      Schweiz
    </p>

    <a href="mailto:info@zimmerpflanzen.de">{{ "info@zimmerpflanzen.de" }}</a>

    <p>CHE-187.845.224</p>

    <h3>Haftungsausschluss</h3>
    <p>
      Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
      Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit
      der Informationen. Haftungsansprüche gegen den Autor wegen Schäden
      materieller oder immaterieller Art, welche aus dem Zugriff oder der
      Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch
      Missbrauch der Verbindung oder durch technische Störungen entstanden sind,
      werden ausgeschlossen. Alle Angebote sind unverbindlich. Der Autor behält
      es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
      gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
      Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>

    <h3>Haftung für Links</h3>
    <p>
      Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
      Verantwortungsbereichs Es wird jegliche Verantwortung für solche Webseiten
      abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf
      eigene Gefahr des Nutzers oder der Nutzerin.
    </p>
  </div>
</app-container>
