import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://0a895639218420404eeea1c9eda26e17@o296516.ingest.us.sentry.io/4508380227108864',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.4, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  autoSessionTracking: true,
  attachStacktrace: true,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/zimmerpflanzen\.de\/store-api/,
    /^https:\/\/dev\.zimmerpflanzen\.de\/store-api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.env,
  release: environment.version,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
