<div
  class="flex flex-col space-y-4 py-6 divide-y divide-gray-900/10"
  [formGroup]="customerForm"
>
  <app-form-section
    [header]="'Lieferadresse'"
    [description]="'Wohin sollen deine Bestellung?'"
  >
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <app-select
        [label]="'Anrede'"
        class="md:col-span-2"
        [control]="customerForm.controls.salutationId"
      >
        @if (salutations(); as salutationState) {
          @for (salutation of salutationState.elements; track salutation.id) {
            <option
              [value]="salutation.id"
              [label]="salutation.displayName"
            ></option>
          }
        }
      </app-select>

      <app-input
        [label]="'Vorname'"
        [control]="customerForm.controls.firstName"
        autocomplete="given-name"
      ></app-input>
      <app-input
        [label]="'Nachname'"
        [control]="customerForm.controls.lastName"
        autocomplete="family-name"
      ></app-input>

      <div class="md:col-span-2">
        <app-input
          [label]="'Straße und Hausnummer'"
          [control]="customerForm.controls.street"
          [autocomplete]="'shipping address-line1'"
        ></app-input>
        @if (missingStreetNumber() && customerForm.controls.street.touched) {
          <div
            class="text-primary-orange text-sm bg-primary-orange/10 border px-2 py-0.5 rounded mt-2"
          >
            Eine Hausnummer fehlt
          </div>
        }
      </div>
      <app-input
        [label]="'PLZ'"
        [control]="customerForm.controls.zipcode"
        [autocomplete]="'shipping postal-code'"
      ></app-input>
      <app-input
        [label]="'Ort'"
        [control]="customerForm.controls.city"
        [autocomplete]="'shipping address-level2'"
      ></app-input>

      <app-select
        [label]="'Land'"
        class="md:col-span-2"
        [control]="customerForm.controls.countryId"
      >
        @if (countries(); as countryState) {
          @if (!countryState.loading) {
            @for (country of countryState.elements; track country.id) {
              <option [value]="country.id" [label]="country.name"></option>
            }
          }
        }
      </app-select>

      <app-input
        [label]="'E-Mail'"
        [control]="customerForm.controls.email"
        class="md:col-span-2"
        autocomplete="email"
      ></app-input>

      <app-input
        [label]="'Telefonnummer'"
        class="md:col-span-2"
        [control]="customerForm.controls.phone"
        autocomplete="tel"
      ></app-input>
      <div class="text-xs md:col-span-2">
        Die Telefonummer wird für die Zustellung verwendet
      </div>
    </div>
  </app-form-section>

  <app-form-section
    [header]="'Zahlungsmittel'"
    [description]="'Wähle deine Zahlungsmittel'"
  >
    <app-payment-options
      [paymentMethodControl]="customerForm.controls.paymentMethodId"
    ></app-payment-options>
  </app-form-section>

  <app-form-section
    [header]="'Bestellübersicht'"
    [description]="'Überprüfe deine Bestellung'"
  >
    <div class="flex flex-col space-y-6">
      @if (showSummary()) {
        @if (cartState(); as cartState) {
          <div
            class="bg-white border border-gray-200 rounded divide-gray-200 divide-y"
          >
            @if (!cartState.loading) {
              @for (item of cartState.lineItems; track item.id) {
                <div class="relative">
                  <div
                    class="h-full px-4 py-2 relative rounded-b-md flex flex-grow min-w-0 items-center"
                  >
                    <div class="space-y-1 w-full">
                      <p class="line-clamp-1 flex-grow min-h-0">
                        {{ item.label }}
                      </p>
                      <div>
                        {{ item.quantity }}x
                        {{ item.price?.unitPrice | currency }}
                      </div>
                    </div>
                    <div>
                      {{ item.price?.totalPrice | currency }}
                    </div>
                  </div>
                </div>
              }

              @for (delivery of cartState.deliveries; track $index) {
                @if (
                  delivery.shippingCosts &&
                  delivery.shippingCosts.totalPrice > 0
                ) {
                  <div class="space-x-3 py-2 flex px-4">
                    <div class="flex-grow min-h-0">Versandkosten</div>
                    <div>
                      {{ delivery.shippingCosts.totalPrice | currency }}
                    </div>
                  </div>
                }
              }

              <div class="space-x-3 py-2 flex px-4">
                <div class="flex-grow min-h-0">Total</div>
                <div>{{ cartState.price.totalPrice | currency }}</div>
              </div>
            }
          </div>
        }
      }

      <!--      <app-checkbox [control]="customerForm.controls.register">-->
      <!--        Ich würde gerne ein Kundenkonto erstellen, um meine Bestellungen-->
      <!--        nachzuverfolgen.-->
      <!--      </app-checkbox>-->

      <app-checkbox [control]="customerForm.controls.dataProtection">
        <span
          >Ich habe die
          <a
            class="underline decoration-primary"
            target="_blank"
            [appEvent]="'open-privacy-policy'"
            href="/Informationen/Datenschutz"
            >Datenschutzbestimmungen</a
          >
          zur Kentniss genommen und die
          <a
            target="_blank"
            class="underline decoration-primary"
            [appEvent]="'open-terms-and-conditions'"
            href="/Informationen/AGB"
            >AGB</a
          >
          gelesen und bin mit ihnen einverstanden.</span
        >
      </app-checkbox>

      @if (cartState(); as cartState) {
        @if (errorMessage(); as error) {
          <div
            class="px-3 py-2 border rounded-md border-primary-orange text-primary-orange bg-primary-orange/10"
          >
            {{ error }}
          </div>
        }

        <app-button-primary
          [action]="buy"
          [appEvent]="'buy'"
          [appEventData]="{
            totalPrice: !cartState.loading ? cartState.price.totalPrice : 0,
          }"
          >Jetzt kaufen</app-button-primary
        >
      }
    </div>
  </app-form-section>
</div>
