<app-benefit-teaser></app-benefit-teaser>

<div
  class="flex flex-col relative md:flex-row md:space-x-6 md:mt-8 max-w-7xl mx-auto w-full px-6 flex-grow min-h-0"
>
  <div
    class="flex flex-col md:w-48 lg:w-60 xl:w-80 flex-shrink-0 -mx-6 md:mx-0"
  >
    <app-search-bar
      class="sticky top-16 md:relative md:top-auto"
    ></app-search-bar>

    <div class="hidden md:block">
      <app-product-categories
        [open]="true"
        [showLabel]="true"
      ></app-product-categories>

      <app-product-sort [open]="true" [showLabel]="true"></app-product-sort>

      <app-product-filter [open]="true" [showLabel]="true"></app-product-filter>
    </div>
  </div>

  <div class="w-full mt-6 md:mt-0 pb-8">
    <app-product-category-header></app-product-category-header>

    <app-product-list
      [store]="store()"
      [canReset]="true"
      (resetted)="reset()"
    ></app-product-list>
  </div>
</div>

<app-product-list-toolbar></app-product-list-toolbar>
