import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { CartService } from '../../cart.service';
import { CartItemsComponent } from '../cart-items/cart-items.component';
import { CartPromotionCodeComponent } from '../cart-promotion-code/cart-promotion-code.component';
import { CartShippingComponent } from '../cart-shipping/cart-shipping.component';
import { CartTotalComponent } from '../cart-total/cart-total.component';
import { ContainerComponent } from '../../components/container/container.component';
import { CheckoutFormComponent } from '../checkout-form/checkout-form.component';
import { CartEmptyComponent } from '../cart-empty/cart-empty.component';
import { NgClass } from '@angular/common';
import { InfoBannerComponent } from '../../info-banner/info-banner.component';

@Component({
  selector: 'app-cart-and-checkout',
  imports: [
    CartItemsComponent,
    CartPromotionCodeComponent,
    CartShippingComponent,
    CartTotalComponent,
    ContainerComponent,
    CheckoutFormComponent,
    CartEmptyComponent,
    NgClass,
    InfoBannerComponent,
  ],
  templateUrl: './cart-and-checkout.component.html',
  styleUrl: './cart-and-checkout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartAndCheckoutComponent {
  readonly cart = inject(CartService);

  store = this.cart.store;

  isEmpty = computed(() => {
    const state = this.store();
    return state.loading ? false : state.lineItems?.length === 0;
  });
}
