<app-info-banner></app-info-banner>

@if (store(); as cart) {
  @if (!cart.loading) {
    <app-container [padding]="'none md:px-6'">
      <div
        class="flex flex-col md:flex-row justify-center md:min-h-[800px]"
        [ngClass]="{ 'items-center': isEmpty() }"
      >
        <div class="flex-shrink-0 bg-white md:border-x space-y-6 flex flex-col">
          @if (!isEmpty()) {
            <h1 class="px-6 py-4 text-xl">Warenkorb</h1>
            <app-cart-items [cartState]="cart"></app-cart-items>
            <app-cart-shipping [cartState]="cart"></app-cart-shipping>
            <app-cart-promotion-code
              [cartState]="cart"
            ></app-cart-promotion-code>
            <app-cart-total [cartState]="cart"></app-cart-total>
          } @else {
            <app-cart-empty [cartState]="cart"></app-cart-empty>
          }
        </div>

        @if (!isEmpty()) {
          <div class="flex-grow bg-white min-w-0 px-6 md:border-r">
            <h1 class="py-4 text-xl border-b">Kasse</h1>
            <app-checkout-form [showSummary]="false"></app-checkout-form>
          </div>
        }
      </div>
    </app-container>
  }
}
