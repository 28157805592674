import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ErrorHandler,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { CurrencyPipe } from '@angular/common';
import { FormSectionComponent } from '../../components/form-section/form-section.component';
import { InputComponent } from '../../components/input/input.component';
import { PaymentOptionsComponent } from '../../components/payment-options/payment-options.component';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectComponent } from '../../components/select/select.component';
import { StoreApiService } from '../../store-api.service';
import { StoreContextService } from '../../store-context.service';
import { CountryService } from '../../country.service';
import { SalutationService } from '../../salutation.service';
import { CartService } from '../../cart.service';
import { AnalyticsService } from '../../analytics.service';
import { environment } from '../../../environments/environment';
import { EventDirective } from '../../event.directive';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomerStateService } from '../../account/customer-state.service';
import { ensureValidity } from '../../form';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { isApiClientError } from '../../account/registration-form/is-api-client.error';
import { Router } from '@angular/router';
import { components } from '../../../api-types/storeApiTypes';

@Component({
  selector: 'app-checkout-form',
  imports: [
    ButtonPrimaryComponent,
    CurrencyPipe,
    FormSectionComponent,
    InputComponent,
    PaymentOptionsComponent,
    ReactiveFormsModule,
    SelectComponent,
    EventDirective,
    CheckboxComponent,
  ],
  templateUrl: './checkout-form.component.html',
  styleUrl: './checkout-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutFormComponent {
  storeApi = inject(StoreApiService);
  storeContext = inject(StoreContextService);
  country = inject(CountryService);
  salutation = inject(SalutationService);
  fb = inject(NonNullableFormBuilder);
  router = inject(Router);
  cart = inject(CartService);
  analytics = inject(AnalyticsService);
  errorHandler = inject(ErrorHandler);
  customerState = inject(CustomerStateService);

  showSummary = input<boolean>(true);
  errorMessage = signal<string>('');

  paymentOptions = viewChild.required(PaymentOptionsComponent);

  missingStreetNumber = computed(() => {
    const street = this.street();
    return !street || !/\d/.test(street);
  });

  customerForm = this.fb.group({
    salutationId: this.fb.control('', [Validators.required]),
    firstName: this.fb.control('', [Validators.required]),
    lastName: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$'),
    ]),
    phone: this.fb.control('', [Validators.required]),
    countryId: this.fb.control('', [Validators.required]),
    street: this.fb.control('', [Validators.required]),
    city: this.fb.control('', [Validators.required]),
    zipcode: this.fb.control('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    dataProtection: this.fb.control(false, [Validators.requiredTrue]),
    register: this.fb.control(false, [Validators.required]),
    password: this.fb.control(''),
    paymentMethodId: this.fb.control('', [Validators.required]),
  });

  street$ = this.customerForm.valueChanges.pipe(map((v) => v.street));
  street = toSignal(this.street$);

  cartState = this.cart.store;
  countries = this.country.store;
  salutations = this.salutation.store;

  constructor() {
    const currentState = this.customerState.customerState();
    if (currentState) {
      this.customerForm.patchValue({
        firstName: currentState.firstName ?? '',
        lastName: currentState.lastName ?? '',
        street: currentState.street ?? '',
        city: currentState.city ?? '',
        zipcode: currentState.zipcode ?? '',
        phone: currentState.phoneNumber ?? '',
        email: currentState.email ?? '',
      });
    }

    effect(() => {
      const paymentMethod = this.storeContext.selectedPaymentMethodId();
      if (paymentMethod) {
        this.customerForm.controls.paymentMethodId.patchValue(paymentMethod);
      }
    });
    effect(() => {
      const salutations = this.salutations();
      if (
        !salutations.loading &&
        !this.customerForm.controls.salutationId.value
      ) {
        this.customerForm.controls.salutationId.patchValue(
          salutations.elements[0].id!,
        );
      }
    });
    effect(() => {
      const countries = this.countries();
      if (!countries.loading && !this.customerForm.controls.countryId.value) {
        this.customerForm.controls.countryId.patchValue(
          countries.elements[0].id!,
        );
      }
    });
    effect(() => {
      const cart = this.cart.store();
      if (cart.loading) {
        return;
      }

      this.analytics.event('begin_checkout', {
        currency: 'EUR',
        value: cart.price.totalPrice,
        items: (cart.lineItems ?? []).map((item, i) => ({
          item_id: item.referencedId,
          item_name: item.label,
          index: i,
          price: item.price?.unitPrice ?? 0,
          quantity: item.quantity,
        })),
      });
    });
  }

  buy = async () => {
    if (!ensureValidity(this.customerForm, { scroll: true })) {
      return;
    }

    await this.paymentOptions().handlePayment();

    const rawValue = this.customerForm.getRawValue();

    if (!this.customerState.isLoggedIn()) {
      try {
        await this.storeApi.apiClient.invoke(
          'register post /account/register',
          {
            body: {
              guest: !rawValue.register,
              email: rawValue.email,
              salutationId: rawValue.salutationId,
              firstName: rawValue.firstName,
              lastName: rawValue.lastName,
              billingAddress: {
                city: rawValue.city,
                zipcode: rawValue.zipcode,
                street: rawValue.street,
                phoneNumber: rawValue.phone,
                countryId: rawValue.countryId,
              },
              password: '',
              acceptedDataProtection: rawValue.dataProtection,
              storefrontUrl: environment.storefrontUrl,
            },
          },
        );
      } catch (e) {
        this.errorHandler.handleError(e);
        if (isApiClientError(e)) {
          if (
            e.details.errors.some(
              (e) => e.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE',
            )
          ) {
            this.errorMessage.set(
              'Email bereits registriert, bitte verwenden Sie eine andere Email-Adresse oder loggen Sie sich ein.',
            );
          } else {
            this.errorMessage.set(
              'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
            );
          }
        }
        return;
      }
    }

    let order: { data: components['schemas']['Order'] } | undefined;

    try {
      order = await this.storeApi.apiClient.invoke(
        'createOrder post /checkout/order',
        {
          body: {},
        },
      );
    } catch (e) {
      this.errorHandler.handleError(e);
      this.errorMessage.set(
        'Ein Bestellfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
      );
    }

    if (!order || !order.data.id) {
      console.error('No order data');
      return;
    }

    try {
      const result = await this.storeApi.apiClient.invoke(
        'handlePaymentMethod post /handle-payment',
        {
          body: {
            orderId: order.data.id,
            finishUrl: `/checkout/${order.data.id}/success`,
            errorUrl: `/checkout/${order.data.id}/error`,
            //stripeDigitalWalletsPaymentMethodId: 'pm_1J3z3eCeV4cSfW5S1hTwukkK'
          },
        },
      );
      if (result && result.data.redirectUrl) {
        window.location.href = result.data.redirectUrl;
      }
    } catch (e) {
      this.errorHandler.handleError(e);
      await this.router.navigate([`/checkout/${order.data.id}/error`]);
    }
  };
}
