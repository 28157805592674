import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Schemas } from '../../../api-types/storeApiTypes';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-product-breadcrumb',
  imports: [RouterLink],
  templateUrl: './product-breadcrumb.component.html',
  styleUrl: './product-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBreadcrumbComponent {
  product = input.required<Schemas['Product']>();

  categories = computed(() => {
    const product = this.product();
    const categories = product.categories;
    if (!product.categoryTree || !categories) {
      return [];
    }

    return product.categoryTree
      .slice(1)
      .map((categoryId) => {
        const category = categories.find(
          (category) => category.id === categoryId,
        );
        if (!category) {
          return undefined;
        }

        return {
          id: category.id,
          name: category.translated.name!,
          url: category.seoUrls ? '/' + category.seoUrls[0]?.seoPathInfo : '',
        };
      })
      .filter(isDefined);
  });
}

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;
