import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { faArrowsUpDown, faEmptySet } from '@fortawesome/pro-duotone-svg-icons';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
import { PropertyGroupIds, PropertyType } from '../../property.service';
import { Schemas } from '../../../api-types/storeApiTypes';
import { getPropertyValue } from './getPropertyValue';

@Component({
    selector: 'app-property',
    imports: [FaIconComponent, NgIf],
    templateUrl: './property.component.html',
    styleUrl: './property.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyComponent {
  icons: Record<string, IconDefinition> = {
    potsize: faEmptySet,
    height: faArrowsUpDown,
  };

  iconEnabled = input<boolean>(true);

  type = input.required<PropertyType>();
  product = input.required<Schemas['Product']>();

  icon = computed(() => this.icons[this.type()]);

  value = computed(() => {
    const groupId = PropertyGroupIds[this.type()];
    const prop = this.product().properties?.find(
      (prop) => prop?.groupId === groupId,
    );
    return getPropertyValue(groupId, prop?.translated['name']);
  });
}
