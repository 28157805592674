import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
} from '@angular/core';
import { ProductService } from '../product.service';
import { ActivatedRoute } from '@angular/router';
import { ProductDetailPageComponent } from '../products/product-detail-page/product-detail-page.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ProductCategoryPageComponent } from '../products/product-category-page/product-category-page.component';
import { CategoryService } from '../category.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-product-or-category-page',
  imports: [ProductDetailPageComponent, ProductCategoryPageComponent],
  templateUrl: './product-or-category-page.component.html',
  styleUrl: './product-or-category-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductOrCategoryPageComponent {
  product = inject(ProductService);
  category = inject(CategoryService);

  route = inject(ActivatedRoute);

  params = toSignal(this.route.params);
  mode = computed(() => {
    const params = this.params();
    const number = params ? params['number'] : '';
    if (number.length === 8 && /\d+/.test(number)) {
      return { type: 'product', number } as const;
    }
    return { type: 'category', subcategory: number } as const;
  });

  constructor() {
    toObservable(this.mode).subscribe(async (params) => {
      if (params.type === 'product') {
        this.product.detailStore.set(params.number);
      } else {
        const categories = await this.category.store().promise;
        const matchingCategory = categories.elements.find(
          (e) =>
            e.url ===
            new URL(environment.baseUrl + location.pathname).pathname + '/',
        );

        this.category.current.set(matchingCategory ?? null);
      }
    });
  }
}
