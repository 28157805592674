import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  inject,
  input,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-button-primary',
  imports: [NgClass, SpinnerComponent],
  templateUrl: './button-primary.component.html',
  styleUrl: './button-primary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonPrimaryComponent {
  disabled = input<boolean>(false);
  action = input.required<() => void | Promise<unknown>>();
  color = input<'orange' | 'green'>('green');
  size = input<'small' | 'medium' | 'large'>('medium');
  cssClass = input<string>('');
  errorHandler = inject(ErrorHandler);

  loading = signal(false);

  async executeAction() {
    if (this.disabled() || this.loading()) {
      return;
    }
    this.loading.set(true);
    try {
      const result = this.action()();
      if (result instanceof Promise) {
        await result;
      }
    } catch (e) {
      this.errorHandler.handleError(e);
      console.error(e);
    } finally {
      this.loading.set(false);
    }
  }
}
