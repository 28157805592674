import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  inject,
  LOCALE_ID,
  provideAppInitializer,
} from '@angular/core';
import {
  provideRouter,
  Router,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
  withIncrementalHydration,
} from '@angular/platform-browser';
import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  registerLocaleData,
} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { EventService } from './event.service';
import * as Sentry from '@sentry/angular';
import { TraceService } from '@sentry/angular';

registerLocaleData(localeDe);

const sentryHandler = Sentry.createErrorHandler();

class MyErrorHandler implements ErrorHandler {
  event = inject(EventService);
  handleError(error: unknown) {
    sentryHandler.handleError(error);
    this.event.push('error', error);
    const chunkFailedMessage = /Loading chunk \d+ failed/;
    if (error instanceof Error && chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    }
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (
          config.width &&
          ['.jpg', '.jpeg', '.png'].some((ext) => config.src.endsWith(ext))
        ) {
          return config.src.replace(
            '/media/',
            `/optimized_media_advanced/${config.width}/`,
          );
        }
        return config.src;
      },
    },
    provideRouter(
      routes,
      withViewTransitions({
        skipInitialTransition: true,
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    //provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({}),
      withIncrementalHydration(),
    ),
    provideHttpClient(withFetch()),
    { provide: ErrorHandler, useClass: MyErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
  ],
};
